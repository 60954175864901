.d-inline-block {
  display: inline-block !important;
}

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.justify-content-center {
  justify-content: center !important;
}

.align-items-center {
  align-items: center !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.w-pre-wrap {
  white-space: pre-wrap !important;
}

.p-sm {
  padding: 8px !important;
}

.py-sm {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-md {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-lg {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.py-xl {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.m-sm {
  margin: 8px !important;
}

a {
  font-family: "Poppins", sans-serif;
}

.bg-f4f6f9 {
  background: #f4f6f9 !important;
}

.text-center {
  text-align: center !important;
}

.login_section {
  height: 100vh;
}
.login_section .login_form_container {
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
}
.login_section .login_form_container .copyright-typography {
  position: absolute;
  bottom: 1vh;
  text-align: center;
  width: 100%;
}
.login_section .login_form_container .logo_form_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.login_section .login_form_container .logo_form_wrapper .login_form_logo {
  width: 200px;
  margin: auto;
}
.login_section .login_form_container .login_btn_wrapper {
  margin: 2vh 0;
}
.login_section .login_form_container .login_form_bottom_links {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.login_section .login_form_container .login_form_bottom_links a {
  text-decoration: none;
  color: #4da2b3;
}
.login_section .login_form_container .login_form_bottom_links a:hover {
  color: #3d8f8f;
  text-decoration: underline;
}
.login_section .login_page_bg_container {
  background: url("https://firebasestorage.googleapis.com/v0/b/yardhotel-bbec3.appspot.com/o/login_page_.png?alt=media&token=6d4113bd-4847-4811-b24a-70830c5fe828");
  height: 100vh;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 5vh 2rem;
}
.login_section .login_page_bg_container .login_page_actions {
  display: flex;
  align-items: center;
  gap: 2rem;
  margin: 2vh 0;
}
.btn-gradient {
  background: linear-gradient(65deg, #ffb624, #f8ca12) !important;
  color: #fff !important;
  text-shadow: 0px 0px 0.5px rgba(0, 0, 0, 0.5);
  font-family: "Poppins", sans-serif !important;
  box-shadow: 0.1px 0.1px 5px #00000033 !important;
}
.btn-gradient:hover {
  box-shadow: 0.1px 1px 8px #00000067 !important;
}

.btn-light-gradient {
  background: linear-gradient(65deg, #fffefa, #f0f0f0) !important;
  color: #f0a000 !important;
  font-family: "Poppins", sans-serif !important;
  box-shadow: 0.1px 0.1px 5px #00000033 !important;
}
.btn-light-gradient:hover {
  box-shadow: 0.1px 1px 8px #00000067 !important;
}

@media only screen and (max-width: 960px) {
  .login_page_bg_container {
    display: none;
  }
}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.position-absolute {
  position: absolute;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.login {
  background: linear-gradient(to right, #ffbf31 0%, #ff890e 100%);
  min-height: 100vh;
}

.min-height-100 {
  min-height: 100vh;
}

.authentication-card {
  width: clamp(400px, 60vw, 600px);
}

.auth-logo {
  width: 180px;
  height: 80px;
  margin: auto;
}

.booking {
  margin-bottom: 1.875rem;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  position: relative;
  border: 0rem solid transparent;
  border-radius: 0.75rem;
  box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
  height: calc(100% - 30px);
}

.booking:hover {
  box-shadow: 0 1.188rem 1.188rem rgba(0, 0, 0, 0.16) !important;
  /* transform: translateY(-1vh); */
}

/*Loader*/
.center_div {
  width: 10rem;
  height: 10rem;
  position: relative;
}

.rot {
  width: 10rem;
  height: 10rem;
  position: absolute;
  border-right: 0.2rem solid #ffbf31;
  border-left: 0.2rem solid #ffbf31;
  border-top: 0.2rem solid #ff890e;
  border-radius: 50%;
  animation: rot 1s linear infinite;
}

@keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading {
  position: absolute;
  color: red;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.linearGradient {
  background: linear-gradient(to right, #ff890e 0%, #ffbf31 100%);
  color: black;
}

.section-padding {
  padding: 7vh 0;
}

.icon-bg {
  color: #ff890e;
  display: inline-block;
  padding: 18px 20px;
  background-color: #f7e1b3;
  border-radius: 8px;
}

.icon-bg:hover {
  background: linear-gradient(to right, #ff890e 0%, #ffbf31 100%);
  color: rgba(0, 0, 0, 0.904);
}

/*notification*/

/*sidebar Card*/
.drawer-card {
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  position: relative;
  border: 0rem solid transparent;
  /* height: calc(100% - 30px); */
  padding: 1.5rem 0;
  margin-bottom: 0.5rem;
  justify-content: center;
  border-radius: 1.125rem;
}

.sidebar-card {
  margin: 0.5rem 2.25rem 4rem 2.25rem;
  border-radius: 1.125rem;
  box-shadow: 0 19px 19px rgba(0, 0, 0, 0.28);
}

.dashboard {
  color: #504242 !important;
}

.detail-panel-bg {
  background: linear-gradient(to right, #ff890e 0%, #ffbf31 100%);
  padding: 1.8rem;
}

.map-container {
  height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}